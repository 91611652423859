import {toAbsoluteUrl} from "./AssetHelpers";

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'uk',
    name: 'Українська',
    flag: toAbsoluteUrl('/media/flags/ukraine.svg'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: toAbsoluteUrl('/media/flags/china.svg'),
  // },
  // {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
  {
    lang: 'bs',
    name: 'Bosanski',
    flag: toAbsoluteUrl('/media/flags/bosnia-and-herzegovina.svg'),
  },
]

export {languages}
