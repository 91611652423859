/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {usePageData} from '../../../layout/core'
import * as Yup from 'yup'
import {
  checkout,
  TPaymentOptions,
  uploadPaymentFile,
} from '../../../../app/pages/balance/core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import Options from './Options'
import {FileInput} from '../../../../app/modules/custom/form-elements/file-inputs/FileInput'
import {ISetFieldValue} from '../../../helpers/custom/tsHelpers/generalHelpers'
import {handleFileUpload} from '../../../helpers/custom/funcs/handleFileUpload'
import {useLang} from '../../../i18n/Metronici18n'
import {allMessages} from '../../../i18n/i18nProvider'

export const checkoutSchema = (intl: any) =>
  Yup.object().shape({
    paymentOption: Yup.string(),
    sum: Yup.number().required(intl.messages.YUP_VALIDATION_PAYMENT_ENTER_SUM).positive(intl.messages.YUP_VALIDATION_PAYMENT_ENTER_SUM),
    comment: Yup.string(),
  })
const CheckoutModalOptions: FC = () => {
  const queryClient = useQueryClient()
  const paymentOptions: TPaymentOptions = queryClient.getQueryData(QUERIES.PAYMENT_OPTIONS) || {}

  // state hooks
  const [submitted, setSubmitted] = useState(false)
  const [cardDataValid, setCDV] = useState({ccnumber: false, ccexp: false, cvv: false})
  const [googleMerchant, setGoogleMerchant] = useState<IGoogleMerchant | null>(null)
  const [total, setTotal] = useState<string>('0')

  // other hooks
  const {pageCustomData, setPageCustomData, addNotification} = usePageData()
  const {currentUser} = useAuth()
  const locale = useLang()
  const intl = useIntl()

  // handle document
  const [docIsLoading, setDocIsLoading] = useState(false)

  const saveDoc = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: ISetFieldValue) => {
    setDocIsLoading(true)

    await handleFileUpload(e, setFieldValue, 'file', 'file', uploadPaymentFile, true)

    setDocIsLoading(false)
  }

  const deleteDoc = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('file', '')
  }

  const charityRound = Boolean(localStorage.getItem('charity.round')).valueOf();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentOption: Object.keys(paymentOptions)[0],
      sum: pageCustomData?.checkoutSum || '',
      sumHrv:
        paymentOptions && pageCustomData?.checkoutSum
          ? +pageCustomData?.checkoutSum *
              +paymentOptions[Object.keys(paymentOptions)[0]]?.currencies?.usd || ''
          : '',
      debts: pageCustomData?.debts,
      comment: '',
      payment_token: '',
      file: {base_url: '', filename: ''},
      charity: {round: charityRound, amount: ''},
    },
    validationSchema: checkoutSchema(intl),

    onSubmit: async (values) => {
      if (values.paymentOption === 'nmi') {
        try {
          // @ts-ignore
          window.CollectJS?.startPaymentRequest()
        } catch (ex) {
          console.error(ex)
        }
      } else {
        setSubmitted(true)
      }
    },
  })
  useEffect(() => {
    const {sum, comment, payment_token, paymentOption, file, charity} = formik.values

    if (submitted && formik.values.sum > 0)
      checkout({
        sum: parseFloat(sum as string),
        comment,
        payment_token,
        provider: paymentOption,
        debts: pageCustomData?.debts,
        file: file.filename,
        charity: {
          round: charity.round,
          amount: parseFloat(charity.amount as string),
        }
      }).then((data) => {
        console.log('data ch', data)

        if (data.success) {
          formik.setStatus(false)
          setPageCustomData({checkoutShow: false, checkoutSum: ''})
          if (pageCustomData?.confirmRequest && pageCustomData?.confirmId) {
            pageCustomData?.confirmRequest(pageCustomData?.confirmId)
          }
          queryClient.invalidateQueries(QUERIES.BALANCE_CURRENT)

          addNotification({
            header: 'GENERAL.DONE_TAB',
            body: 'GENERAL.TOOLTIPS.SUCCESSFUL_PAYMENT',
          })
          if (data.google_merchant) {
            setGoogleMerchant(data.google_merchant)
          }
          formik.resetForm()
          setSubmitted(false)
        } else {
          formik.setStatus(data.err)
        }
      })
  }, [formik.values, submitted])

  const finishSubmit = (response: any) => {
    console.log('res ch', response)
    if (response.google_merchant) {
      setGoogleMerchant(response.google_merchant)
    }
    formik.setFieldValue('payment_token', response.token)

    formik.setSubmitting(false)
    setSubmitted(true)
  }
  const timeout = () => {
    formik.setFieldValue('payment_token', '')
    formik.setSubmitting(false)
    setSubmitted(true)
  }

  useEffect(() => {
    if (pageCustomData?.checkoutShow)
      // @ts-ignore
      window.CollectJS?.configure({
        variant: 'inline',
        invalidCss: {
          color: '#B40E3E',
        },
        validCss: {
          color: '#14855F',
        },
        validationCallback: function (field: string, status: any, message: string) {
          setCDV((prev) => ({...prev, [field]: !!status}))
        },
        timeoutDuration: 10000,
        timeoutCallback: timeout,
        customCss: {
          'background-color': 'transparent',
          border: 'transparent',
          height: '100%',
        },
        styleSniffer: true,
        callback: (response: any) => {
          finishSubmit(response)
        },
        fields: {
          ccnumber: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CC_NUMBER'}),
            selector: '#ccnumber',
          },
          ccexp: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CC_EXPIRATION'}),
            selector: '#ccexp',
          },
          cvv: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CVV'}),
            selector: '#cvv',
          },
        },
      })
  }, [pageCustomData?.checkoutShow, formik.values])

  useEffect(() => {
    if (googleMerchant && locale) {
      const {order_id, merchant_id, email, estimated_delivery_date, delivery_country} =
        googleMerchant
      const loadGCRScript = () => {
        window.renderOptIn = function () {
          window?.gapi?.load('surveyoptin', function () {
            window?.gapi?.surveyoptin.render({
              order_id,
              merchant_id,
              email,
              estimated_delivery_date,
              delivery_country,
              opt_in_style: 'CENTER_DIALOG',
            })
          })
        }

        window.___gcfg = {
          lang: allMessages[locale]?.fullLocale,
        }
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn'
        script.async = true
        script.defer = true

        document.body.appendChild(script)

        return () => {
          document.body.removeChild(script)
          delete window.renderOptIn
          delete window.___gcfg
        }
      }

      const cleanup = loadGCRScript()
      return cleanup
    }
  }, [googleMerchant])
  useEffect(() => {
    if(formik.values.paymentOption === 'nmi'){
      const fee = 1+(paymentOptions[formik.values.paymentOption]?.fee || 0)/100;
      const prep =  (+formik.values.sum + +formik.values.charity.amount)*fee;
      setTotal((formik.values.charity.round && (prep - Math.floor(prep) >= 0.5)) ? Math.round(prep).toFixed(2) : prep.toFixed(2));
    }
  }, [formik.values.paymentOption, formik.values.charity, formik.values.sum]);

  const handleClose = () => setPageCustomData((prev: any) => ({...prev, checkoutShow: false}))

  return (
    <Modal show={pageCustomData?.checkoutShow} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton className='py-4'>
        <Modal.Title className='d-flex flex-column justify-content-start align-items-start'>
          <FormattedMessage id='PAYMENT.ADD_BUTTON' />
          <p className='d-block fw-normal fs-6 m-0'>
            <FormattedMessage
              id={`PAYMENT.FEE_NOTIFICATION.${formik?.values?.paymentOption?.toUpperCase()}`}
              values={{comm: paymentOptions[formik?.values?.paymentOption]?.fee || 0}}
            />
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Options formik={formik} />
        {formik.status && <div className='alert alert-danger'>{formik.status}</div>}

        <form onSubmit={formik.handleSubmit} id='kt_checkout_form'>
          {(formik.values.paymentOption === 'fop' || formik.values.paymentOption === 'card') && (
            <div className='row mb-3'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'></label>{' '}
              <div className='col-lg-8 position-relative d-flex flex-column'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='0.00'
                  {...formik.getFieldProps('sumHrv')}
                  onChange={(event) => {
                    formik.setFieldValue('sumHrv', event.target.value.replace(/,/g, '.'))

                    formik.setFieldValue(
                      'sum',

                      parseFloat(
                        (
                          +event.target.value.replace(/,/g, '.') /
                          +paymentOptions[formik.values.paymentOption]?.currencies?.usd
                        )
                          .toFixed(2)
                          .toString()
                      )
                    )
                  }}
                />

                <span
                  className={`fs-6 fw-normal position-absolute`}
                  style={{
                    left: '80%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  ₴
                </span>
                {formik.touched.sumHrv && formik.errors.sumHrv && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sumHrv}</div>
                  </div>
                )}
              </div>
            </div>
          )}{' '}
          <div className='row'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
              <FormattedMessage id='PAYMENT.FORM.SUM' />
            </label>

            <div className='col-lg-8 position-relative d-flex flex-column'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0.00'
                {...formik.getFieldProps('sum')}
                onChange={(event) => {
                  const sum = event.target.value.replace(/,/g, '.');
                  formik.setFieldValue('sum', sum)

                  formik.setFieldValue(
                    'sumHrv',

                    parseFloat(
                      (
                        +sum *
                        +paymentOptions[formik.values.paymentOption]?.currencies?.usd
                      )
                        .toFixed(2)
                        .toString()
                    )
                  )
                }}
              />
              <span
                className={`fs-6 fw-normal position-absolute`}
                style={{
                  right: '10%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                $
              </span>
              {formik.touched.sum && formik.errors.sum && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.sum}</div>
                </div>
              )}
            </div>
          </div>

          {pageCustomData?.showPayAll === true &&
            currentUser?.balance &&
            currentUser?.balance < 0 && (
              <div className="row">
                <div className="col-lg-8 offset-lg-4">
                  <div className='row d-flex justify-content-center'>
                    <button
                      type='button'
                      className='text-gray-400 btn btn-sm text-hover-primary ps-0'
                      onClick={() => {
                        setPageCustomData({
                          ...pageCustomData,
                          checkoutSum: Math.abs(currentUser?.balance || 0),
                        })
                      }}
                    >
                      <FormattedMessage id='PAYMENT.FORM.PAY_DEBT' /> ${Math.abs(currentUser?.balance || 0)}
                    </button>
                  </div>
                </div>
              </div>
            )}
          {formik.values.paymentOption === 'nmi' && (
            <>
              <div className="my-3 text-muted text-end">
                <FormattedMessage id='PAYMENT.FORM.TOTAL'/> ${total}
              </div>
              <div className="my-3">
                <div className="accordion accordion-icon-toggle">
                  <div className="mb-3">
                    <div className="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#charity_options">
                  <span className="accordion-icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                      <h3 className="fs-4 fw-semibold mb-0 ms-4"><FormattedMessage id='PAYMENT.FORM.CHARITY_LABEL'/></h3>
                    </div>

                    <div id="charity_options" className="fs-6 collapse">
                      <div className='d-flex flex-gutter w-100 g-3'>
                        <div className="fv-row d-flex w-50 flex-column mb-5">
                          <div className="form-check">
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='paymentOption'
                              value={1}
                              checked={formik.values.charity.round}
                              onChange={(e) => {
                                formik.setFieldValue('charity.round', e.target.checked)
                                localStorage.setItem('charity.round', String(e.target.checked))
                              }}
                            />
                            <span className='d-flex align-items-center me-2'>
                          <span className='d-flex flex-column'>
                            <span className='fw-bolder fs-6'><FormattedMessage id='PAYMENT.FORM.CHARITY_ROUND'/></span>
                          </span>
                        </span>
                          </div>
                        </div>
                        <div className="fv-row d-flex w-50 flex-column mb-5">
                          <label className="d-flex position-relative fs-5 flex-column me-2 fw-bold mb-2" htmlFor="charity.amount">
                            <input
                              type="number"
                              className="form-control form-control-lg form-control-solid"
                              {...formik.getFieldProps('charity.amount')}
                            />
                            <span className="fs-6 fw-normal InputTemplate_addSymbol">$</span>
                          </label>
                          <div className='d-flex flex-row justify-content-center'>
                            {[1, 5, 10].map(sum => (
                              <button
                                type='button'
                                className='text-gray-400 btn btn-sm text-hover-primary'
                                onClick={() => {
                                  formik.setFieldValue('charity.amount', sum)
                                }}
                              >
                                ${sum}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {' '}
              <div className='row mb-3'>
                <div className='col-lg-12'>
                  <div className='input-group'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='lar la-credit-card fs-2'></i>
                    </span>
                    <div
                      id='ccnumber'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-lg-6'>
                  <div className='input-group'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='lar la-calendar fs-2'></i>
                    </span>
                    <div
                      id='ccexp'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div
                    id='cvv'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
                  />
                </div>
              </div>
            </>
          )}{' '}
          {paymentOptions[formik.values.paymentOption]?.optional_fields?.file && (
            <div className='pt-5 w-100'>
              {' '}
              <FileInput
                inputName='file'
                inputId='payment-file'
                setFieldValue={formik.setFieldValue}
                uploadedFile={{
                  base: formik.values?.file?.base_url || '',
                  link: formik.values?.file?.filename || '',
                }}
                uploadFunction={saveDoc}
                deleteFunction={deleteDoc}
                loading={docIsLoading}
                formikHook={false}
              />
            </div>
          )}
          <div className='row mb-3'>
            <label className='form-label fw-bold fs-6'>
              <FormattedMessage id='PAYMENT.FORM.COMMENT'/>
            </label>

            <div className='col-lg-12'>
              <textarea
                rows={3}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                {...formik.getFieldProps('comment')}
              />
              {formik.touched.comment && formik.errors.comment && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.comment}</div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* {JSON.stringify(cardDataValid)} */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-secondary fw-bold'
          disabled={formik.isSubmitting}
          onClick={handleClose}
        >
          <FormattedMessage id='GENERAL.BUTTONS.CLOSE'/>
        </button>
        <button
          id='kt_checkout_submit'
          form='kt_checkout_form'
          type='submit'
          className='btn btn-primary fw-bold'
          disabled={
            formik.isSubmitting ||
            (formik.values.paymentOption === 'nmi' &&
              !(cardDataValid.ccnumber && cardDataValid.ccexp && cardDataValid.cvv))
          }
        >
          {!formik.isSubmitting && (
            <FormattedMessage id='GENERAL.BUTTONS.CONFIRM_PAYMENT' values={{sum: null}}/>
          )}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='GENERAL.BUTTONS.WAIT'/>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export {CheckoutModalOptions}
